/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icons, Checkbox } from 'sarsaparilla';
import { startCase } from 'lodash';
import {
    computeLocationStateCode,
    getLocationDisplayName,
    isLocTopLevelForUser,
} from '../../utilities/locations';
import { displayStringForRoleType } from '../../utilities/roles';
import SimpleTable from '../tableUtil/SimpleTable';

const propTypes = {
    canEditLocations: PropTypes.bool,
    onEditLocations: PropTypes.func,
    roles: PropTypes.array,
    onRoleDelete: PropTypes.func,
    onDefaultRoleChange: PropTypes.func,
    showBorders: PropTypes.bool,
    showHeader: PropTypes.bool,
    showDefaultColumn: PropTypes.bool,
    showDeleteColumn: PropTypes.bool,
    editingSelf: PropTypes.bool,
};

export default function RolesTable({
    canEditLocations,
    onEditLocations,
    roles,
    onRoleDelete,
    onDefaultRoleChange,
    showBorders = false,
    showHeader = true,
    showDefaultColumn,
    showDeleteColumn,
    editingSelf,
}) {
    const displayTable = roles && roles.length > 0;

    const columns = React.useMemo(() => {
        const renderCheckbox = (row) => {
            return (
                <Checkbox
                    id={row.id}
                    value={row.id}
                    isChecked={row.original.is_default_role}
                    onChange={() => onDefaultRoleChange(row.original)}
                    label={`Set ${row.original.location.location_description} as default`}
                    isLabelVisible={false}
                />
            );
        };
        const renderDeleteButton = (row, data) => {
            if (editingSelf && isLocTopLevelForUser(data, row.original.location)) {
                return null;
            }
            return (
                <Button onClick={() => onRoleDelete(row.original)} appearance="link">
                    <b>DELETE</b>
                </Button>
            );
        };

        const cols = [
            {
                Header: 'LOCATION',
                id: 'location-name',
                accessor: (row) => startCase(getLocationDisplayName(row.location)),
                minWidth: 100,
            },
            {
                Header: 'STATE',
                id: 'state-code',
                accessor: (row) => computeLocationStateCode(row.location).toUpperCase(),
                minWidth: 30,
            },
            {
                Header: 'ROLE',
                id: 'role-name',
                accessor: (row) => displayStringForRoleType(row.role_type),
                minWidth: 50,
            },
        ];
        if (showDefaultColumn) {
            cols.unshift({
                Header: 'DEFAULT',
                id: 'default-select',
                Cell: ({ row }) => renderCheckbox(row),
                sortable: false,
                minWidth: 90,
                maxWidth: 90,
            });
        }
        if (showDeleteColumn) {
            cols.push({
                Header: 'DELETE',
                id: 'delete-column',
                Cell: ({ row, data }) => renderDeleteButton(row, data),
                sortable: false,
                minWidth: 80,
                maxWidth: 80,
            });
        }
        return cols;
    }, [
        onDefaultRoleChange,
        onRoleDelete,
        showDefaultColumn,
        showDeleteColumn,
        editingSelf,
    ]);

    let roleCount = '';
    if (roles && roles.length > 5) {
        roleCount = ` (${roles.length})`;
    }
    return (
        <div className="update-user-roles-wrapper">
            {showHeader && (
                <div className="update-user-roles-table-header">
                    Assigned Locations and Roles {roleCount}
                </div>
            )}
            {canEditLocations && !editingSelf && (
                <Button
                    appearance="link"
                    className="mb-3"
                    iconBeforeElement={<Icons.IconAddCircle />}
                    onClick={onEditLocations}
                >
                    Edit Locations & Roles
                </Button>
            )}
            {displayTable && (
                <SimpleTable
                    data={roles}
                    defaultSortId="location-name"
                    noDataText=""
                    columns={columns}
                    showBorders={showBorders}
                />
            )}
        </div>
    );
}

RolesTable.propTypes = propTypes;
