/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Alert, TextField, TYPES } from 'sarsaparilla';
import { cancelChangePassword, changePassword } from '../actions/changePassword';
import { ensureLoggedInUserInState } from '../actions/login';
import PageHeader from '../components/PageHeader';
import { errorForChangePassword } from '../utilities/errorMessages';
import { passwordCriteriaCheck } from '../utilities/validation';
import SiteWrapper from './SiteWrapper';

const propTypes = {
    history: TYPES.HISTORY,
    dispatch: PropTypes.func,
    cancelChangePassword: PropTypes.func,
    changePassword: PropTypes.func,
    error: PropTypes.string,
    userMustSetPassword: PropTypes.bool,
};

export class ChangePassword extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            newError: false,
            confirmError: false,
            criteria: passwordCriteriaCheck(''),
            confirmMatch: false,
        };
    }

    componentDidMount() {
        const { dispatch, history } = this.props;

        dispatch(ensureLoggedInUserInState(history));
    }

    handleCurrentPasswordChange(e) {
        this.setState({ currentPassword: e.target.value });
    }

    handleNewPasswordChange(e) {
        this.setState({ newPassword: e.target.value }, this.doCriteriaCheck.bind(this));
    }

    handleConfirmNewPasswordChange(e) {
        this.doCriteriaCheck();
        this.setState(
            { confirmNewPassword: e.target.value },
            this.doCriteriaCheck.bind(this)
        );
    }

    getCriteriaClass(criteria, errored) {
        return classnames({
            'criteria-check': true,
            'criteria-passed': criteria,
            'criteria-failed': errored && !criteria,
        });
    }

    cancel = () => {
        this.props.cancelChangePassword(this.props.history);
    };

    buttonHeader() {
        return (
            <div className="change-password-button-header">
                {!this.props.userMustSetPassword && (
                    <input
                        type="button"
                        className="change-password-cancel rec-button-tertiary"
                        onClick={this.cancel}
                        value="Cancel"
                    />
                )}
                <input
                    type="button"
                    className="change-password-save rec-button-primary"
                    onClick={this.changePassword.bind(this)}
                    value="Save"
                />
            </div>
        );
    }

    error() {
        if (this.props.error) {
            return (
                <p>
                    <span className="change-password-error-asterisk">*</span>
                    {errorForChangePassword(this.props.error)}
                </p>
            );
        }

        return null;
    }

    inputKeyUp(e) {
        if (e.keyCode === 13) {
            this.changePassword(e);
        }
    }

    changePassword(e) {
        e.preventDefault();
        let pass = this.state.newPassword.length > 0;
        const criteria = this.state.criteria;
        Object.keys(criteria).forEach((key) => {
            pass = pass && criteria[key];
        });

        const match = this.state.confirmMatch;

        if (pass && match) {
            this.props.changePassword(
                this.state.currentPassword,
                this.state.newPassword,
                this.state.confirmNewPassword,
                this.props.history
            );
        } else {
            this.setState({ newError: !pass, confirmError: !match });
        }
    }

    doCriteriaCheck() {
        this.setState((prevState) => {
            const criteria = passwordCriteriaCheck(prevState.newPassword);
            const match = prevState.newPassword === prevState.confirmNewPassword;
            return {
                ...prevState,
                criteria,
                confirmMatch: match,
                newError: false,
                confirmError: false,
            };
        });
    }

    form() {
        return (
            <form className="change-password-form">
                <div className="rec-grid-12-12">
                    <div className="rec-form-item-wrap">
                        <TextField
                            label="Current Password"
                            type="password"
                            id="change-current-password"
                            className="change-password-input"
                            onChange={this.handleCurrentPasswordChange.bind(this)}
                            onKeyUp={this.inputKeyUp.bind(this)}
                            value={this.state.currentPassword}
                            placeholder="Enter Current Password"
                            enableShowPassword
                        />
                    </div>
                    <div className="rec-form-item-wrap">
                        <TextField
                            label="New Password"
                            type="password"
                            id="change-new-password"
                            className={classnames({
                                'change-password-input': true,
                                'rec-error': this.state.newError,
                            })}
                            onChange={this.handleNewPasswordChange.bind(this)}
                            onKeyUp={this.inputKeyUp.bind(this)}
                            value={this.state.newPassword}
                            placeholder="Enter New Password"
                            enableShowPassword
                        />
                        <div className="change-password-criteria for-new-password">
                            <div className="criteria-check criteria-label">
                                Password must contain:
                            </div>
                            <div className="criteria-inner-wrap">
                                <div
                                    className={this.getCriteriaClass(
                                        this.state.criteria.length,
                                        this.state.newError
                                    )}
                                >
                                    12+ characters
                                </div>
                                <div
                                    className={this.getCriteriaClass(
                                        this.state.criteria.lower,
                                        this.state.newError
                                    )}
                                >
                                    Lower case
                                </div>
                                <div
                                    className={this.getCriteriaClass(
                                        this.state.criteria.upper,
                                        this.state.newError
                                    )}
                                >
                                    Upper case
                                </div>
                                <div
                                    className={this.getCriteriaClass(
                                        this.state.criteria.number,
                                        this.state.newError
                                    )}
                                >
                                    Number
                                </div>
                                <div
                                    className={this.getCriteriaClass(
                                        this.state.criteria.symbol,
                                        this.state.newError
                                    )}
                                >
                                    Symbol
                                </div>
                            </div>
                        </div>
                        <div className="criteria-inner-wrap">
                            Password reuse not allowed. Your new password must be
                            significantly different from your previous passwords.
                        </div>
                    </div>
                    <div className="rec-form-item-wrap">
                        <TextField
                            label="Confirm New Password"
                            type="password"
                            id="change-confirm-new-password"
                            className={classnames({
                                'change-password-input': true,
                                'rec-error': this.state.confirmError,
                            })}
                            onChange={this.handleConfirmNewPasswordChange.bind(this)}
                            onKeyUp={this.inputKeyUp.bind(this)}
                            value={this.state.confirmNewPassword}
                            placeholder="Confirm New Password"
                            enableShowPassword
                        />
                        <div className="change-password-criteria for-confirm-password">
                            <div
                                className={classnames({
                                    required: true,
                                    hidden: !this.state.confirmError,
                                })}
                            >
                                <span className="change-password-error-asterisk">*</span>
                                Passwords do not match
                            </div>
                        </div>
                    </div>
                    {this.error()}
                </div>
            </form>
        );
    }

    render() {
        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader title="My Profile - Change Password" />
                </div>
                {this.props.userMustSetPassword && (
                    <div className="must-change-password-alert">
                        <Alert type="warning">
                            Your password has expired, please set a new password.
                        </Alert>
                    </div>
                )}
                <div className="page-content wrapper">
                    <div className="change-password-wrapper">
                        {this.buttonHeader()}
                        {this.form()}
                    </div>
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.changePassword.error,
        userMustSetPassword: state.login.user.must_set_password,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        cancelChangePassword: (history) => dispatch(cancelChangePassword(history)),
        changePassword: (currentPassword, newPassword, confirmedNewPassword, history) =>
            dispatch(
                changePassword(
                    currentPassword,
                    newPassword,
                    confirmedNewPassword,
                    history
                )
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
