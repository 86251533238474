/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    ButtonGroup,
    FlexCol,
    FlexRow,
    FormattedDateTime,
    Heading,
    ModalActions,
    StyledModal,
    Text,
} from '@fp/sarsaparilla';

import { expireRangerAppToken } from '../../actions/rangerAppToken';

function ConfirmExpirationTokenModal({ token, closeModal, modalAction, reloadTokens }) {
    const dispatch = useDispatch();

    const expireRangerToken = async () => {
        try {
            await dispatch(expireRangerAppToken(token.token_id));
            modalAction('success', 'Token expired successfully');
            reloadTokens();
        } catch (e) {
            modalAction('error', 'Internal error, please try again later');
        }
    };

    return (
        <StyledModal
            size="lg"
            isOpen={true}
            heading="Expire Token"
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => closeModal()}
        >
            <Heading headingLevel={4} appearance="h5" className="mb-4">
                Are you sure you want to expire this token?
            </Heading>

            <FlexRow>
                <FlexCol className="mb-3" sm={12} md={6}>
                    <Text fontWeight="semibold">Token Id</Text>
                    <Text>{token.token_id}</Text>
                </FlexCol>
                <FlexCol className="mb-3" sm={12} md={6}>
                    <Text fontWeight="semibold">Created by</Text>
                    <Text>{token.created_by_name}</Text>
                </FlexCol>
            </FlexRow>
            <FlexRow>
                <FlexCol className="mb-3" sm={12} md={6}>
                    <Text fontWeight="semibold">Expiration Date</Text>
                    <Text>
                        <FormattedDateTime date={new Date(token.expire_at)} format="L" />
                    </Text>
                </FlexCol>
                <FlexCol className="mb-3" sm={12} md={6}>
                    <Text fontWeight="semibold">Device Id</Text>
                    <Text>{token.device_id ?? 'Not Assigned'}</Text>
                </FlexCol>
            </FlexRow>
            <FlexRow>
                {token.device_nickname && (
                    <FlexCol className="mb-3" sm={12} md={6}>
                        <Text fontWeight="semibold">Device Nickname</Text>
                        <Text>{token.device_nickname}</Text>
                    </FlexCol>
                )}
            </FlexRow>

            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance="tertiary" onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    <Button onClick={expireRangerToken}>Expire</Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

export default ConfirmExpirationTokenModal;
