/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { v4 as uuidv4 } from 'uuid';

export type OidcConfig = {
    authorizeUrl: string;
    state: string;
    nonce: string;
    clientID: string;
    acrValues: string;
    prompt: string;
    redirectUri: string;
    responseType: string;
    scope: string;
};

export default function useOidcConfig(): OidcConfig {
    return {
        nonce: uuidv4(),
        acrValues: encodeURIComponent(`${process.env.OIDC_ACR_VALUES}`),
        authorizeUrl: `${process.env.OIDC_AUTH_URL}`,
        state: uuidv4(),
        clientID: `${process.env.OIDC_CLIENT_ID}`,
        prompt: `${process.env.OIDC_PROMPT}`,
        redirectUri: `${process.env.OIDC_REDIRECT_URI}`,
        responseType: `${process.env.OIDC_RESPONSE_TYPE}`,
        scope: `${process.env.OIDC_SCOPE}`,
    };
}
