/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as errors from '../constants/errors';

const errorContainsExpectedError = (errorParam: any, expected: string): boolean => {
    let error = errorParam;
    if (!error.indexOf) {
        error = error.message;
    }

    return error ? error.indexOf(expected) !== -1 : false;
};

const errorForSettingPassword = (error: string): string => {
    if (error === errors.MALFORMED_TOKEN) {
        return 'We encountered an issue setting your password. Please verify that the reset password link was neither modified nor previously used and try again.';
    }

    if (error === errors.PASSWORDS_DO_NOT_MATCH) {
        return "The passwords you entered don't match up. Please check and try again.";
    }

    if (error === errors.CANNOT_REUSE_PASSWORD) {
        return 'Please provide a new password. Password reuse is not allowed.';
    }

    if (error === errors.PASSWORD_RESET_TOO_RECENTLY) {
        return 'Your password has been reset too recently. Please allow 24 hours between password resets.';
    }

    if (
        errorContainsExpectedError(error, errors.PASSWORD_REQUIRES_LOWER_CASE) ||
        errorContainsExpectedError(error, errors.PASSWORD_REQUIRES_UPPER_CASE) ||
        errorContainsExpectedError(error, errors.PASSWORD_REQUIRES_SPECIAL_CHARACTER) ||
        errorContainsExpectedError(error, errors.PASSWORD_REQUIRES_NUMBER) ||
        errorContainsExpectedError(error, errors.PASSWORD_TOO_SHORT)
    ) {
        return 'Your first password attempt doesn’t meet our criteria. Passwords must have 12 characters including a capital letter, lower case letter, special character, and number. Please try again.';
    }

    return 'There was an error setting your password.';
};

export const errorForChangePassword = (error: string) => {
    let message: string = errorForSettingPassword(error);

    if (message === '') {
        // general unexpected error message
        message =
            'We encountered an issue with changing your password. Please try again.';
    }

    return message;
};

export const errorForResetPassword = (error: string): string => {
    let message: string = errorForSettingPassword(error);

    if (message === '') {
        // general unexpected error message
        message = 'There was an error resetting your password.';
    }

    return message;
};

// error in this function is an actual Error object, not a string
export const errorForListingUsers = (errMsg: string): string => {
    let message: string =
        'We encountered a server issue with retrieving this user list. Please try again.';

    if (errorContainsExpectedError(errMsg, errors.NOT_AUTHORIZED)) {
        message = 'You are not authorized to view this information.';
    } else if (errorContainsExpectedError(errMsg, errors.SERVER_ERROR)) {
        message =
            'This account does not have the necessary authorization to view this information. Please confirm your account permissions or contact us if you believe you should be able to view this information.'; // TODO: Contact link
    } else if (errorContainsExpectedError(errMsg, errors.USER_ACTION_FORBIDDEN)) {
        message =
            'You do not have permission to view the Attestation Facility Managers for this location.';
    }

    return message;
};

export const errorForLogin = (error: string, attempts: number): string | undefined => {
    if (!error) {
        return undefined;
    }

    let message: string =
        'We were unable to log you in with the information provided. Please double check your email and password, and try again. You can also click the link below to reset your password.';

    if (attempts > 1) {
        message =
            'We were unable to log you in with the information provided. Please double check your email and password, and try again. Your account will be locked after 3 failed attempts. You can also click the link below to reset your password.';
    }

    if (errorContainsExpectedError(error, errors.EMAIL_NOT_CONFIRMED)) {
        return 'We need to verify your email address. Please check your email for the verification link to confirm your account.';
    }

    if (errorContainsExpectedError(error, errors.EMAIL_CHANGE_NOT_CONFIRMED)) {
        return 'We were unable to confirm your email change. Please double check your email, and try again later.';
    }

    if (errorContainsExpectedError(error, errors.TOO_MANY_LOGIN_ATTEMPTS)) {
        return 'There have been too many unsuccessful attempts to sign in to this account. Please check your account information and try again later.'; // TODO: Time left?
    }

    if (errorContainsExpectedError(error, errors.ACCOUNT_IS_LOCKED)) {
        return "There have been too many unsuccessful attempts to sign in and we have locked this account to protect your information. Please contact us and we'll help you get back in to your account."; // TODO: Contact link
    }

    if (errorContainsExpectedError(error, errors.NOT_AUTHORIZED)) {
        return 'Your account is no longer authenticated. If you cannot log in, contact your manager.';
    }

    if (errorContainsExpectedError(error, errors.MFA_REJECTED)) {
        return 'Code not accepted, try again';
    }

    return message;
};

export const errorForExceedMaxAttestingFM = (
    error: Error
): Error & { message: string } => {
    return {
        ...error,
        message:
            'One of the assigned locations listed below already has the maximum number of Attesting Facility Managers.',
    };
};

export const errorForUpdateFacility = (): string => {
    return 'We encountered an issue with saving these updates. Please try again.';
};

export const errorForGetMachineApiKeys = (): string => {
    return 'We encountered an issue while loading your machine API keys. Please try again later.';
};

export const errorForGenerateMachineApiKey = (): string => {
    return 'We encountered an issue while generating your machine API key. Please try again later.';
};

export const errorForDisableMachineApiKey = (): string => {
    return 'We encountered an issue while disabling your machine API key. Please try again later.';
};

export const errorForGetMachineApiPermissions = (): string => {
    return 'We encountered an issue while loading your machine API permissions. Please try again later.';
};

export const errorForUpdateMachineApiPermissions = (): string => {
    return 'We encountered an issue while updating your machine API permissions. Please try again later.';
};

export const errorForUpdateUser = (errorParam: Error): string => {
    let message: string =
        'We encountered an issue with saving these updates. Please try again.';

    const error: Error = errorParam;
    if (errorContainsExpectedError(error, errors.INVALID_PHONE_NUMBER)) {
        message =
            'International numbers are not allowed. Please double check the phone number you are trying to enter.';
    } else if (error.message) {
        message = error.message;
    } else if (errorContainsExpectedError(error, errors.NO_ROLES_TO_UPDATE)) {
        message = 'At least on role must be updated.';
    } else if (
        errorContainsExpectedError(error, errors.MUST_ASSIGN_ROLE_AT_EACH_LOCATION)
    ) {
        message =
            'As part of the account management process, a role must be assigned for every location. Please  take a moment to review the information and assign a role for every location.';
    } else if (errorContainsExpectedError(error, errors.LOCKED_USER_REQUIRES_NOTE)) {
        message =
            'As part of the account management process, a note explaining the reason for locking a user account is required. Please update the information with a reason for locking this account.';
    } else if (errorContainsExpectedError(error, errors.USER_WITH_EMAIL_ALREADY_EXISTS)) {
        message = `This email address is already assigned to another location. To add this user at your facility, please contact your ${process.env.SITE_NAME} Program Manager.`;
    } else if (errorContainsExpectedError(error, errors.GET_USER_FAILED)) {
        message = 'An error occurred while fetching the user.';
    } else if (errorContainsExpectedError(error, errors.CONFIRMATION_EMAIL_NOT_SENT)) {
        message =
            "User was successfully created, however the server was unable to send a confirmation email. Please try again from the user's profile page.";
    }

    return message;
};

export const errorForAssigningLocations = (error: Error): string => {
    // TODO this general message along with others in this file likely should come from UI/UX team
    let message =
        'We encountered an issue with assigning these locations. Please try again.';

    if (errorContainsExpectedError(error, errors.MUST_ASSIGN_ONE_LOCATION)) {
        message =
            'As part of the account management process, every user must be assigned at least one location. Please refer to the table below and assign this user a location.';
    }

    return message;
};

export const errorForLoadingLocations = (): string => {
    return 'We encountered an issue with loading the list of locations you manage. Please try again.';
};

export const errorForAssigningConcessionaire = (error: Error): string => {
    // TODO this general message along with others in this file likely should come from UI/UX team
    let message: string =
        'An error occurred while trying to create the concessionaire user.';

    if (errorContainsExpectedError(error, errors.MUST_ASSIGN_ONE_CONCESSIONAIRE)) {
        message =
            'A concessionaire user must be assigned at least one concessionaire. Use the table below to assign a concessionaire.';
    }
    if (errorContainsExpectedError(error, errors.MAY_ASSIGN_ONLY_ONE_CONCESSIONAIRE)) {
        message = 'A user may only operate under a single concessionaire.';
    }

    return message;
};

export const errorForLoadingConcessionaires = (): string => {
    return 'An error occurred loading the list of the concessionaires that you manage.';
};

export const errorForResendConfirmationEmail = (error: Error, ttl: number): string => {
    const number: number = Math.ceil(ttl / 60);
    let message: string =
        'An error occurred while attempting to resend the confirmation email.';

    if (errorContainsExpectedError(error, errors.RESEND_CONFIRMATION_ALREADY_SENT)) {
        message = `A confirmation email has been sent too recently. Please wait ${number} minute${
            number !== 1 ? 's' : ''
        } before trying again.`;
    }

    return message;
};

export const errorForEnablingPasswordReset = (): string => {
    return 'An error occurred sending the password reset email.';
};

export const errorForConfirmEmail = (): string => {
    return 'We encountered an issue with confirming your email. Please try again.';
};

export const errorForPasswordRecovery = (): string => {
    return 'We encountered an issue with recovering the password for this email. Please try again.';
};

export const errorForLocationsLineLoader = (): string => {
    return 'We encountered an issue with loading these locations. Please try again. ';
};

export const errorForDeletingUser = (error: Error): string => {
    switch (error.message) {
        case errors.NON_SUBORDINATE_ROLE:
            return 'This user cannot be deleted because they have a role at another location. You can delete an individual role in the user’s profile.';
        case errors.USER_ACTION_FORBIDDEN:
        default:
            return 'We encountered an issue with deleting users. Please try again. ';
    }
};

export const errorForUserAttestation = (): string => {
    return 'We encountered an issue with attesting users. Please try again.';
};

export const errorForUpdateUserStatus = (): string => {
    return 'We encountered an issue with updating user status. Please try again.';
};

export const errorForCooperators = (error: string): string => {
    switch (error) {
        case errors.GET_COOPERATORS_FAILED:
            return 'An error occurred while trying to load the list of cooperators.';
        case errors.GET_COOPERATOR_EMPLOYEES_FAILED:
            return 'An error occurred while trying to load the list of employees.';
        case errors.SAVE_COOPERATOR_FAILED:
            return 'An error occurred while trying to save a cooperator. The company name may not be blank and must be unique, and the phone number and email must be valid.';
        case errors.SAVE_COOPERATORS_FAILED:
            return 'An error occurred while trying to save cooperators. Company names may not be blank and must be unique, and the phone numbers and emails must be valid.';
        case errors.SAVE_COOPERATOR_EMPLOYEES_FAILED:
            return 'An error occurred while trying to save cooperator employees.';
        case errors.DELETE_COOPERATORS_FAILED:
            return 'An error occurred while trying to delete cooperators.';
        case errors.DELETE_COOPERATOR_EMPLOYEES_FAILED:
            return 'An error occurred while trying to delete cooperator employees.';
        case errors.REQUIRE_UNIQUE_COOPERATOR_NAME:
            return 'You must provide a unique name for each new cooperator.';
        default:
            return 'An error occurred while attempting to reach the server.';
    }
};

export const errorForConcessionaires = (error: string): string | null => {
    if (error) {
        switch (error) {
            case errors.GET_CONCESSIONAIRES_FAILED:
                return 'An error occurred while trying to load the list of concessionaires.';
            case errors.GET_CONCESSIONAIRE_PERMITS_FAILED:
                return 'An error occurred while trying to load the list of concessionaire permits.';
            default:
                return 'An error occurred while attempting to reach the server.';
        }
    } else {
        return null;
    }
};

export const errorForSavingCooperatorEmployees = (errorParam: Error) => {
    let message: string = 'An error occurred while trying to save cooperator employees.';

    let errorStr: string = '';
    if (errorParam.message) {
        errorStr = errorParam.message;
    }

    if (errorContainsExpectedError(errorStr, errors.USER_WITH_EMAIL_ALREADY_EXISTS)) {
        message = 'A user with specified email already exists.';
    }

    return message;
};

export const errorForFetchingFacilityInfo = (): string => {
    return 'An error occurred while trying to load facility information.';
};

export const errorForSubmittingPOC = (): string => {
    return 'An error occurred while submitting POC information.';
};

export const errorForFetchingModalSection = (sectionTitle: string): string => {
    return `An error occurred while fetching data for '${sectionTitle}'.`;
};

export const errorForMfaTotp = (): string => {
    return 'An error occurred while setting up your MFA.';
};

export const errorForMfaOptOut = (): string => {
    return 'An error occurred while opting out your MFA.';
};

export const errorForMfaFactor = (): string => {
    return 'An error occurred while validating your MFA code.';
};

export const errorForMfaBackupCodes = (): string => {
    return 'An error occurred while creating your MFA backup codes.';
};
