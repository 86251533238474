/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import * as types from '../constants/types';
import * as globals from '../constants/globals';
import * as errors from '../constants/errors';
import { doneLoading, startLoading } from './loading';
import {
    loggedIn,
    JSONContentType,
    makeAuthorizedRequest,
    logIn,
    resetSession,
} from './login';
import {
    setAuthStorage,
    claimIsResetPasswordMFA,
} from '../utilities/internalSecurityUtil';
import { setSelectedLocationRole } from './selectedLocationRole';

export const validationSuccessful = (resetToken) => {
    return {
        type: types.VALIDATE_SUCCESSFUL,
        resetToken,
    };
};

export const passwordResetMFAValidateSuccessful = (resetToken) => {
    return {
        type: types.MFA_RESET_PASSWORD_SUCCESSFUL,
        resetToken,
    };
};

export const validationSuccessfulMFA = (resetTokenMFA) => {
    return {
        type: types.VALIDATE_SUCCESSFUL_MFA,
        resetTokenMFA,
    };
};

export const passwordReset = (account) => {
    return {
        type: types.PASSWORD_RESET,
        account,
    };
};

export const resetPasswordFailed = (errorMessage) => {
    return {
        type: types.RESET_PASSWORD_FAILED,
        error: errorMessage,
    };
};

export const validateEmailToken = (token) => {
    return async (dispatch) => {
        return axios
            .post(
                `${globals.API_URL}/reset-password/email-validation`,
                { token },
                {
                    headers: {
                        'Content-Type': JSONContentType,
                    },
                }
            )
            .then((response) => {
                if (!(response.status >= 200 && response.status < 300)) {
                    return Promise.reject(new Error(response.statusText));
                }

                if (claimIsResetPasswordMFA(response.data)) {
                    dispatch(validationSuccessfulMFA(response.data));
                } else {
                    dispatch(validationSuccessful(response.data));
                }

                return Promise.resolve();
            })
            .catch(() => {
                dispatch(resetPasswordFailed(errors.MALFORMED_TOKEN));
            });
    };
};

export const resetPasswordV2 = (token, password, confirmPassword, history) => {
    if (password !== confirmPassword) {
        return resetPasswordFailed(errors.PASSWORDS_DO_NOT_MATCH);
    }
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const headers = new Headers();
            headers.append('Content-Type', JSONContentType);

            const response = await axios.post(
                `${globals.API_URL}/reset-password/reset`,
                { token, password },
                { headers }
            );

            const responseData = response?.data;

            if (responseData.error) {
                dispatch(resetPasswordFailed(responseData.error));
                dispatch(doneLoading());
            } else if (response.status >= 200 && response.status < 300) {
                //it worked, resetting stale data
                dispatch(resetSession());
                //log the user in
                setAuthStorage(responseData);
                dispatch(setSelectedLocationRole(null));
                dispatch(passwordReset(responseData.user));
                dispatch(doneLoading());
                dispatch(loggedIn(responseData, null, history));
            } else {
                dispatch(resetPasswordFailed('Error resetting your password'));
                dispatch(doneLoading());
            }
        } catch (e) {
            const message = e?.response?.data?.error ?? 'Error resetting your password';
            dispatch(resetPasswordFailed(message));
            dispatch(doneLoading());
        }
    };
};

export const resetPassword = (token, password, confirmPassword, history) => {
    if (password !== confirmPassword) {
        return resetPasswordFailed(errors.PASSWORDS_DO_NOT_MATCH);
    }
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const headers = new Headers();
            headers.append('Content-Type', JSONContentType);

            const data = {
                token,
                password,
            };

            const response = await axios.post(
                `${globals.API_URL}/reset-password/${data.token}`,
                data,
                {
                    withCredentials: true,
                    headers,
                }
            );

            const responseData = response?.data;

            if (responseData.error) {
                dispatch(resetPasswordFailed(responseData.error));
                dispatch(doneLoading());
            } else if (response.status >= 200 && response.status < 300) {
                responseData.access_token = token;

                setAuthStorage(responseData);

                dispatch(setSelectedLocationRole(null));
                dispatch(passwordReset(responseData));

                dispatch(doneLoading());
                dispatch(
                    logIn(
                        responseData.user.email,
                        password,
                        '/internal/account/hub',
                        '',
                        history
                    )
                );
            } else {
                dispatch(resetPasswordFailed('Error resetting your password'));
                dispatch(doneLoading());
            }
        } catch (e) {
            dispatch(resetPasswordFailed('Error resetting your password'));
            dispatch(doneLoading());
        }
    };
};

const enablingPasswordResetFailed = (error) => {
    return {
        type: types.ENABLING_PASSWORD_RESET_FAILED,
        error,
    };
};

const enablingPasswordResetSuccess = (userId) => {
    return {
        type: types.ENABLING_PASSWORD_RESET_SUCCESS,
        userId,
    };
};

export const enablePasswordReset = (userId) => {
    return async (dispatch) => {
        dispatch(startLoading());

        try {
            const url = `${globals.API_URL}/enable-password-update`;
            const data = JSON.stringify({
                user_id: userId,
            });

            const response = await makeAuthorizedRequest(url, 'POST', dispatch, data);
            dispatch(enablingPasswordResetSuccess(response.user_id));
        } catch (error) {
            dispatch(enablingPasswordResetFailed(error));
        }

        dispatch(doneLoading());
    };
};

export const passwordResetMFAValidate = (token, mfaCode, mfaFactor) => {
    return async (dispatch) => {
        dispatch(startLoading());

        try {
            const headers = new Headers();
            headers.append('Content-Type', JSONContentType);

            const response = await axios.post(
                `${globals.API_URL}/reset-password/mfa`,
                {
                    token,
                    mfa_token: mfaCode,
                    mfa_factor: mfaFactor,
                },
                {
                    headers,
                }
            );

            dispatch(passwordResetMFAValidateSuccessful(response.data));
        } catch (error) {
            dispatch(resetPasswordFailed(error));
        }

        dispatch(doneLoading());
    };
};
