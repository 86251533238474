/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Button,
    Divider,
    Icons,
    FeatureFlag,
    PhoneNumberField,
    RadioToggle,
} from 'sarsaparilla';

const propTypes = {
    phoneNumbers: PropTypes.array,
    canSave: PropTypes.bool,
    onExtChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    onNumberChange: PropTypes.func.isRequired,
    onRemovePhone: PropTypes.func.isRequired,
    onAddPhone: PropTypes.func.isRequired,
    disabledAddPhone: PropTypes.bool,
};

export default function PhoneNumbers({
    phoneNumbers,
    canSave,
    onExtChange,
    onTypeChange,
    onNumberChange,
    onRemovePhone,
    onAddPhone,
    disabledAddPhone,
}) {
    const phoneTypes = ['work', 'cell'];
    const phoneTypesMaps = {
        work: 'Office',
        cell: 'Mobile',
    };
    const phoneOptions = [
        {
            label: 'Office / Landline',
            value: 'work',
        },
        {
            label: 'Mobile Phone',
            value: 'cell',
        },
    ];
    const phoneOptionsMaps = {
        work: 'Office / Landline',
        cell: 'Mobile Phone',
    };

    const renderRadioGroupModeLegacy = () => {
        return (
            <div className="rec-nested-wrap">
                <div className="rec-page-layout-full-width">
                    {!!canSave && (
                        <div className="rec-form-item-wrap mt-2">
                            <div className="rec-icon">
                                <Button
                                    appearance="link"
                                    iconBeforeElement={<Icons.IconAddCircle />}
                                    onClick={onAddPhone}
                                    disabled={disabledAddPhone}
                                >
                                    Add Phone Number
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className="rec-form-item-wrap">
                        {!!phoneNumbers &&
                            phoneNumbers.map((phone, index) => {
                                const key = `phone_number_${index}`;
                                return (
                                    <div className="rec-form-item-wrap" key={key}>
                                        <div className="phone-number-wrap" id={key}>
                                            <div className="phone-number">
                                                {phoneTypes.map((type) => {
                                                    const selected = phone.type === type;
                                                    const classes = classnames({
                                                        'rec-label-radio': true,
                                                        selected,
                                                    });

                                                    return (
                                                        <label
                                                            htmlFor={`phone-${type}-radio-${index}`}
                                                            className={classes}
                                                            key={`phone-${type}`}
                                                        >
                                                            <span
                                                                data-text={
                                                                    phoneTypesMaps[type]
                                                                }
                                                                className="phone-label-for"
                                                            >
                                                                {phoneTypesMaps[type]}
                                                            </span>
                                                            <input
                                                                type="radio"
                                                                className="rec-input-hide"
                                                                id={`phone-${type}-radio-${index}`}
                                                                name={`phone-type-radio-${index}`}
                                                                value={
                                                                    selected
                                                                        ? 'on'
                                                                        : 'off'
                                                                }
                                                                checked={selected}
                                                                onChange={() =>
                                                                    onTypeChange(
                                                                        type,
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                            <span className="rec-input-radio" />
                                                        </label>
                                                    );
                                                })}
                                                <PhoneNumberField
                                                    id={`phone-number-${index}`}
                                                    value={phone.phone}
                                                    type="tel"
                                                    onChange={(event) =>
                                                        onNumberChange(event, index)
                                                    }
                                                    label="Phone Number"
                                                    shouldReformatValue
                                                    placeholder="Enter Phone Number"
                                                />
                                            </div>
                                            <div
                                                className={`phone-extension ${phone.type !== 'work' ? 'hidden' : ''}`}
                                            >
                                                <label htmlFor={key}>Extension</label>
                                                <input
                                                    value={phone.ext}
                                                    type="text"
                                                    id={`phone-ext-${index}`}
                                                    onChange={(event) =>
                                                        onExtChange(event, index)
                                                    }
                                                    placeholder="Enter Ext "
                                                />
                                            </div>
                                            <div className="phone-remove">
                                                <button
                                                    type="button"
                                                    className="phone-remove-link  button-as-anchor"
                                                    onClick={() => onRemovePhone(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    };

    const renderRadioToggleModeV2 = () => {
        return (
            <div className="user-phone-wrap rec-nested-wrap">
                <div className="rec-page-layout-full-width">
                    <div className="rec-form-item-wrap">
                        {!!phoneNumbers &&
                            phoneNumbers.map((phone, index) => {
                                const key = `phone_number_${index}`;
                                return (
                                    <div className="rec-form-item-wrap" key={key}>
                                        {index > 0 && (
                                            <div className="phone-number-divider">
                                                <Divider />
                                            </div>
                                        )}
                                        <div className="phone-number-wrap" id={key}>
                                            <div className="phone-type mt-3 mr-2 mb-1">
                                                <RadioToggle
                                                    options={phoneOptions}
                                                    value={phone.type}
                                                    onChange={(e) =>
                                                        onTypeChange(
                                                            e.target.value,
                                                            index
                                                        )
                                                    }
                                                    isRequired
                                                />
                                            </div>
                                            <div className={`phone-number-${phone.type}`}>
                                                <div className="phone-work">
                                                    <PhoneNumberField
                                                        id={`phone-number-${index}`}
                                                        value={phone.phone}
                                                        type="tel"
                                                        onChange={(event) =>
                                                            onNumberChange(event, index)
                                                        }
                                                        label={`${phoneOptionsMaps[phone.type]} Number`}
                                                        shouldReformatValue
                                                        placeholder="Enter Phone Number"
                                                    />
                                                </div>
                                                <div
                                                    className={`phone-ext ${phone.type !== 'work' ? 'hidden' : ''}`}
                                                >
                                                    <label htmlFor={key}>
                                                        Office / Landline Extension
                                                    </label>
                                                    <input
                                                        value={phone.ext}
                                                        type="text"
                                                        id={`phone-ext-${index}`}
                                                        onChange={(event) =>
                                                            onExtChange(event, index)
                                                        }
                                                        placeholder="Enter Ext "
                                                    />
                                                </div>
                                                <div className="phone-delete">
                                                    <Button
                                                        appearance="subtle-danger"
                                                        iconBeforeElement={
                                                            <Icons.IconDelete size="md" />
                                                        }
                                                        onClick={() =>
                                                            onRemovePhone(index)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    {!!canSave && (
                        <div className="rec-form-item-wrap mt-5">
                            <div className="rec-icon">
                                <Button
                                    appearance="link"
                                    iconBeforeElement={<Icons.IconAddCircle />}
                                    onClick={onAddPhone}
                                    disabled={disabledAddPhone}
                                >
                                    Add Phone Number
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <FeatureFlag flag="iaEditUserV2" fallback={renderRadioGroupModeLegacy()}>
            {renderRadioToggleModeV2()}
        </FeatureFlag>
    );
}

PhoneNumbers.propTypes = propTypes;
